import './AssembledProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { QuantityInputGroup } from './QuantityInputGroup';

interface AssembledProductTileProps {
  className?: string;
  componentSku: b2x.AssembledComponentSku;
  productsPerRow?: number;
}

export const AssembledProductTile = ({ className, componentSku, productsPerRow }: AssembledProductTileProps) => {
  const product = componentSku.sku?.product;
  const sku = componentSku.sku;

  if (product === undefined) {
    throw new Error(`Product undefined for componentSku: ${componentSku.code}`);
  }

  if (sku === undefined) {
    throw new Error(`Sku undefined for componentSku: ${componentSku.code}`);
  }

  product.skus = [sku];

  const priceHelper = b2x.usePrice(product, sku.id);

  return (
    <div className="product-tile card border-0 my-3">
      <div className="product-tile-basic-info">
        <div className="product-tile-image-container position-relative mb-3 bg-gray-100">
          <b2x.EqualHeightElement name={'product-tile-image'}>
            <b2x.Image
              alt={product.name}
              aspectRatio={b2x.appConfig.productImageAspectRatio}
              className="d-block"
              fluid
              // format={imageFormat}
              src={sku.image ? sku.image.src : product.image?.src}
            />
          </b2x.EqualHeightElement>
          {priceHelper.discountPercentage && (
            <div className="discount-percentage position-absolute top-0 start-0 py-1 py-md-2">
              <span className="bg-red text-white fw-bold lh-1 py-1 ps-3 pe-2 rounded-end">{`-${b2x.formatFloatPercentage(
                priceHelper.discountPercentage
              )}%`}</span>
            </div>
          )}
        </div>
        <div className="mb-2">
          <b2x.EqualHeightElement name={`product-title-row-${productsPerRow}`}>
            <h4 className="m-0 mb-1 product-title">
              <span className="text-blue fw-bold">{b2x.formatHtml(product.name)}</span>
            </h4>
            {sku.name !== product.name && <div className="extra-small mb-0 text-blue fw-semi-bold m-0">{sku.name}</div>}
          </b2x.EqualHeightElement>
        </div>
      </div>
      <div className="product-tile-extra-info">
        <div className={classnames({ 'd-none d-lg-block': sku.state === 'AVAILABLE' }, 'mb-1')}>
          <div className="hstack flex-wrap">
            <div className="me-2">
              <b2x.PriceBlock
                classNames={{ specialPrice: 'fw-bold text-red' }}
                gap={2}
                hiddenDiscountPercentage
                priceHelper={priceHelper}
              />
            </div>
            {priceHelper.perUnit?.price && (
              <div className="extra-small">
                {`(${t('misc.pricePerUnit', {
                  measurementUnit: priceHelper.perUnit.measurement,
                  price: b2x.formatCurrency(priceHelper.perUnit.price),
                })})`}
              </div>
            )}
          </div>
        </div>

        {sku.state === 'AVAILABLE' ? (
          <div className="d-flex align-items-center gap-2 gap-md-3">
            <div className="d-none d-lg-inline">
              <QuantityInputGroup
                decreaseButton={{ disabled: !componentSku.removeEnabled, onClick: componentSku.removeQuantity }}
                increaseButton={{ disabled: !componentSku.addEnabled, onClick: componentSku.addQuantity }}
                quantity={componentSku.quantity}
              />
            </div>
            <div className="d-lg-none">
              <b2x.PriceBlock
                classNames={{ specialPrice: 'fw-bold text-red' }}
                gap={2}
                hiddenDiscountPercentage
                priceHelper={priceHelper}
              />
              {priceHelper.perUnit?.price && (
                <div className="extra-small">
                  {`(${t('misc.pricePerUnit', {
                    measurementUnit: priceHelper.perUnit.measurement,
                    price: b2x.formatCurrency(priceHelper.perUnit.price),
                  })})`}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="d-grid my-2">
            <Button className="btn-sm" disabled label={t('misc.productNotAvailable')} variant="outline-primary" />
          </div>
        )}
        {b2x.appConfig.enableBestPrice && (
          <div className="extra-small lh-1 mt-2">
            <b2x.BestPrice priceHelper={priceHelper} />
          </div>
        )}
      </div>
    </div>
  );
};
