import './AssembledProductPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext } from '../AppContext';
import { AssembledProductTile } from '../AssembledProductTile';
import { Button } from '../Button';
import { CircleProgressBar } from '../CircleProgressBar';
import { Container } from '../Container';
import { AssembledProductPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { QuantityInputGroup } from '../QuantityInputGroup';
import { Page } from './Page';

export interface AssembledProductPageProps {}

export const AssembledProductPage = (props: AssembledProductPageProps) => {
  const _product = b2x.useProduct();

  const { addToCart, assembledProduct, product } = b2x.useProductAssembler({
    productId: _product?.id,
  });

  const content = b2x.useContent<AssembledProductPageContentType>('ASSEMBLED_PRODUCT_PAGE_CONTENT', undefined, {
    suppressErrorLog: true,
  });

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[assembledProduct]}>
      {assembledProduct && (
        <>
          {content?.body.header?.asset && <b2x.Asset {...content.body.header.asset} fluid />}
          <Container>
            <div className="pt-3 pt-md-5">
              {/* <b2x.Row>
                <b2x.Col size={{ lg: 8, xs: 12 }}> */}
              {content?.body.copy?.title && (
                <h1 className="text-gold mb-3">{b2x.formatHtml(content.body.copy.title)}</h1>
              )}
              {content?.body.copy?.description && (
                <div className="mb-3">{b2x.formatHtml(content.body.copy.description)}</div>
              )}
              {assembledProduct.assembledComponents?.map((assembledComponent) => (
                <b2x.Div key={assembledComponent.id} marginBottom={5}>
                  <b2x.Row>
                    <b2x.Col size={{ lg: 8, xs: 12 }}>
                      <h3 className="py-2 text-center mb-3">{assembledComponent.name}</h3>
                      {assembledComponent.componentSkus && (
                        <ProductsTiles products={assembledComponent.componentSkus} productsPerRow={{ md: 3, xs: 2 }} />
                      )}
                    </b2x.Col>
                    <b2x.Col size={{ lg: 4, xs: 12 }}>
                      <ComponentSummary assembledComponent={assembledComponent} />
                    </b2x.Col>
                  </b2x.Row>
                  <hr />
                </b2x.Div>
              ))}
              <b2x.Row>
                <b2x.Col size={{ lg: 8, xs: 12 }}></b2x.Col>
                <b2x.Col size={{ lg: 4, xs: 12 }}>
                  <Summary addToCart={addToCart} assembledProduct={assembledProduct} />
                </b2x.Col>
              </b2x.Row>
              {/* </b2x.Col>
                <b2x.Col className="d-none d-lg-block" size={4}> */}
              {/* <Summary addToCart={addToCart} assembledProduct={assembledProduct} product={product} /> */}
              {/* </b2x.Col>
              </b2x.Row> */}
            </div>
          </Container>
          <StickyFooter addToCart={addToCart} assembledProduct={assembledProduct} product={product} />
        </>
      )}
    </Page>
  );
};

type ProductsPerRow = 1 | 2 | 3 | 4;

interface ProductsTilesProps {
  products: Array<b2x.AssembledComponentSku>;
  productsPerRow: Partial<Record<b2x.Breakpoint, ProductsPerRow>>;
}

interface ProductTilesWrapperProps extends ProductsTilesProps {
  equalHeightContext: b2x.EqualHeightContextInterface;
  tempProductPerRow: number;
}

const ProductTilesWrapper = ({
  equalHeightContext,
  products,
  productsPerRow,
  tempProductPerRow,
}: ProductTilesWrapperProps) => {
  const windowSize = b2x.useWindowSize();
  const windowHeight = windowSize.height;
  const windowWidth = windowSize.width;

  const equalHeightStable = b2x.useStable(equalHeightContext);

  React.useEffect(() => {
    equalHeightStable.updateTrigger();
  }, [tempProductPerRow, equalHeightStable, windowWidth, windowHeight]);

  return (
    <b2x.Row cols={productsPerRow} gap={{ md: 4, xs: 3 }}>
      {products.map((componentSku, index) => (
        <b2x.Col key={componentSku.code}>
          <AssembledProductTile
            componentSku={componentSku}
            productsPerRow={Math.floor((index + 0) / tempProductPerRow)}
          />
        </b2x.Col>
      ))}
    </b2x.Row>
  );
};

const ProductsTiles = ({ productsPerRow, ...otherProps }: ProductsTilesProps) => {
  const breakpoint = b2x.useBreakpoint();
  let tempProductPerRow = 3;

  if (breakpoint === 'xs') {
    tempProductPerRow = productsPerRow.xs ? productsPerRow.xs : tempProductPerRow;
  }
  if (breakpoint === 'sm') {
    tempProductPerRow = productsPerRow.sm ? productsPerRow.sm : tempProductPerRow;
  }
  if (breakpoint === 'md') {
    tempProductPerRow = productsPerRow.md ? productsPerRow.md : tempProductPerRow;
  }
  if (breakpoint === 'lg') {
    tempProductPerRow = productsPerRow.lg ? productsPerRow.lg : tempProductPerRow;
  }
  if (breakpoint === 'xl') {
    tempProductPerRow = productsPerRow.xl ? productsPerRow.xl : tempProductPerRow;
  }
  if (breakpoint === 'xxl') {
    tempProductPerRow = productsPerRow.xxl ? productsPerRow.xxl : tempProductPerRow;
  }

  return (
    <b2x.EqualHeight timeout={50}>
      {(equalHeightContext) => (
        <ProductTilesWrapper
          equalHeightContext={equalHeightContext}
          productsPerRow={productsPerRow}
          tempProductPerRow={tempProductPerRow}
          {...otherProps}
        />
      )}
    </b2x.EqualHeight>
  );
};

interface StickyFooterProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  product?: b2x.ProductApiDto;
}

const StickyFooter = ({
  addToCart,
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  product,
}: StickyFooterProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [SummaryOffcanvas, showSummaryOffcanvas] = useSummaryOffcanvas({
    addToCart: addToCart,
    assembledProduct: assembledProduct,
    className: 'assembled-product-summary-offcanvas',
    componentSkuCrossedOutPrice: componentSkuCrossedOutPrice,
    componentSkuPrice: componentSkuPrice,
    placement: 'bottom',
    product: product,
  });

  const assembledComponent = assembledProduct.assembledComponents?.at(0);

  return (
    <div
      className="assembled-product-sticky-footer position-sticky d-grid bottom-0 start-0 w-100 d-block d-lg-none"
      ref={ref}
      style={{ zIndex: 1000 }}
    >
      <Button className="bg-primary text-white py-3" onClick={showSummaryOffcanvas} variant="blank">
        <Container>
          <b2x.Row gap={2}>
            <b2x.Col className="d-flex align-items-center" size={'auto'}>
              <Icon name="cart" size={25} />
            </b2x.Col>
            <b2x.Col className="small text-start lh-sm" size={''}>
              <div>{t('assembledProduct.summary.subtitle')}</div>
              {assembledComponent && (
                <div className="fw-normal">
                  {t('assembledProduct.summary.status', {
                    maxProduct: Math.floor(assembledComponent.maxQty / 24),
                    productAdded: Math.floor(assembledComponent.curQty / 24),
                  })}
                </div>
              )}
            </b2x.Col>
            <b2x.Col size={'auto'}>
              {assembledComponent && componentSkuPrice && (
                <div className="extra-small fw-normal">
                  {b2x.formatCurrency(Math.floor(assembledComponent.curQty / 24) * componentSkuPrice)}
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        </Container>
      </Button>
      {SummaryOffcanvas}
    </div>
  );
};

interface ComponentSummaryProps {
  assembledComponent: b2x.AssembledComponent;
  // product?: b2x.ProductApiDto;
}

const ComponentSummary = ({ assembledComponent }: ComponentSummaryProps) => {
  const { headerHeight } = useAppContext();

  const summaryTop = (headerHeight ?? 0) + 20;
  return (
    <div
      className="position-sticky sticky-top"
      style={{
        top: summaryTop,
        zIndex: 1,
      }}
    >
      <div key={assembledComponent.id}>
        <div className="assembled-progress text-center mb-3">
          <div className="d-flex justify-content-center mb-4">
            <CircleProgressBar
              // label={t('assembledProduct.summary.yourAssembly')}
              barColor="#A71930"
              size={230}
              strokeLineCap="square"
              strokeWidth={20}
              valueCurrent={assembledComponent.curQty}
              valueFrom={assembledComponent.curQty}
              valueTo={assembledComponent.maxQty}
            />
          </div>
        </div>
        <div className="summary-item-list">
          {assembledComponent.componentSkus
            ?.filter((componentSku) => componentSku.quantity > 0)
            .map((componentSku) => (
              <SummaryItem componentSku={componentSku} key={componentSku.code} />
            ))}
        </div>
      </div>
    </div>
  );
};

interface SummaryProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
}

const Summary = ({ addToCart, assembledProduct }: SummaryProps) => {
  return (
    <div>
      <h3 className="fw-bold text-center">{t('assembledProduct.summary.title')}</h3>
      <div className="bg-white position-sticky bottom-0 pb-3">
        <div className="hstack justify-content-between fw-bold py-3">
          <div>{t('assembledProduct.summary.total')}</div>
          <div>{b2x.formatCurrency(assembledProduct.totalPrice)}</div>
        </div>
        <div className="d-grid">
          <Button
            disabled={!assembledProduct.completed}
            label={
              assembledProduct.completed ? t('assembledProduct.addToCart') : t('assembledProduct.completeTheAssembly')
            }
            onClick={addToCart}
          />
        </div>
      </div>
      {/* */}
    </div>
  );
};

interface SummaryItemProps {
  componentSku: b2x.AssembledComponentSku;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
}

const SummaryItem = ({ componentSku, componentSkuCrossedOutPrice, componentSkuPrice }: SummaryItemProps) => {
  return (
    <div className="summary-item">
      <div className="border-bottom py-3" key={componentSku.sku?.id}>
        <b2x.Div marginBottom={2}>
          <b2x.Row gap={3}>
            <b2x.Col size={2}>
              <b2x.Image
                fluid
                {...componentSku.sku?.image}
                aspectRatio={b2x.appConfig.productImageAspectRatio}
                format={288}
              />
            </b2x.Col>
            <b2x.Col size={10}>
              <div className="small">
                <p className="fw-bold mb-0">{componentSku.sku?.product?.name}</p>
                <p className="mb-0">{componentSku.sku?.name}</p>
              </div>
            </b2x.Col>
          </b2x.Row>
        </b2x.Div>
        <b2x.Div alignItems="center" display="flex" justifyContent="spaceBetween">
          <QuantityInputGroup
            decreaseButton={{ disabled: !componentSku.removeEnabled, onClick: componentSku.removeQuantity }}
            increaseButton={{ disabled: !componentSku.addEnabled, onClick: componentSku.addQuantity }}
            quantity={componentSku.quantity}
            size={30}
          />
          <Button
            className="extra-small text-gray-400 text-decoration-underline fw-normal"
            label={t('assembledProduct.removeFromAssembly')}
            onClick={componentSku.remove}
            size="small"
            variant="blank"
          />
        </b2x.Div>
      </div>
    </div>
  );
};

interface SummaryOffcanvasProps extends b2x.OffcanvasProps {
  addToCart(): void;
  assembledProduct: b2x.AssembledProduct;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  product?: b2x.ProductApiDto;
}

const SummaryOffcanvas = ({
  addToCart,
  assembledProduct,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  product,
  ...offcanvasProps
}: SummaryOffcanvasProps) => {
  const assembledComponent = assembledProduct.assembledComponents?.at(0);

  return (
    <b2x.Offcanvas {...offcanvasProps}>
      {({ close }) => (
        <>
          <b2x.OffcanvasHeader smallPadding title="Riepilogo del tuo menu">
            {assembledComponent && (
              <div>
                {t('assembledProduct.summary.status', {
                  maxProduct: Math.floor(assembledComponent.maxQty / 24),
                  productAdded: Math.floor(assembledComponent.curQty / 24),
                })}
              </div>
            )}
          </b2x.OffcanvasHeader>
          <b2x.OffcanvasBody className="p-3 pb-0">
            <div className="footer-mobile-offcanvas">
              <h3 className="fw-bold text-center">{t('assembledProduct.summary.title')}</h3>
              {assembledComponent && (
                <div>
                  <div className="assembled-progress text-center mb-3">
                    <div className="d-flex justify-content-center">
                      <CircleProgressBar
                        label={t('assembledProduct.summary.yourAssembly')}
                        size={180}
                        strokeLineCap="square"
                        strokeWidth={15}
                        valueCurrent={Math.floor(assembledComponent.curQty / 24)}
                        valueFrom={Math.floor(assembledComponent.curQty / 24)}
                        valueTo={Math.floor(assembledComponent.maxQty / 24)}
                      />
                    </div>
                  </div>
                  <div className="summary-item-list">
                    {assembledComponent.componentSkus
                      ?.filter((componentSku) => componentSku.quantity > 0)
                      .map((componentSku) => (
                        <SummaryItem
                          componentSku={componentSku}
                          componentSkuCrossedOutPrice={componentSkuCrossedOutPrice}
                          componentSkuPrice={componentSkuPrice}
                          key={componentSku.code}
                        />
                      ))}
                  </div>
                </div>
              )}
              <div className="bg-white position-sticky bottom-0">
                {assembledComponent && componentSkuPrice && (
                  <div className="hstack justify-content-between fw-bold py-4">
                    <div>{t('assembledProduct.summary.total')}</div>
                    <div>{b2x.formatCurrency(Math.floor(assembledComponent.curQty / 24) * componentSkuPrice)}</div>
                  </div>
                )}
                <div className="d-grid pb-3">
                  <Button
                    disabled={!assembledProduct.completed}
                    label={
                      assembledProduct.completed
                        ? t('assembledProduct.addToCart')
                        : t('assembledProduct.completeTheAssembly')
                    }
                    onClick={addToCart}
                  />
                </div>
              </div>
            </div>
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

export const useSummaryOffcanvas = (props?: SummaryOffcanvasProps) =>
  b2x.useStaticModal(SummaryOffcanvas, 'offcanvas', props);
