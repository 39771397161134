import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { MiscellaneousContentType, ProductPromoCodeContentType } from '../contentTypes';
import { CustomSkuRadio } from '../CustomSkuRadio';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import animazioneCampanella from '../images/product/animazione-campanella.json';
import animazioneSveglia from '../images/product/animazione-sveglia.json';
import imageAlDente from '../images/product/icon-al-dente.svg';
import imageEssiccazione from '../images/product/icon-essiccazione.svg';
import imageKcal from '../images/product/icon-kcal.svg';
import { QuantityInputGroup } from '../QuantityInputGroup';
import { ProductsSlider } from '../slider/ProductsSlider';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  // const productInShippingCountryLocale = b2x.useProductInShippingCountryLocale({
  //   id: product?.id,
  // });

  const { isFooterCopyrightVisible, isTopBarRowVisible } = useAppContext();

  const [variantsDivContainer, scrollToVariantsDivContainer] = b2x.useScrollTo(-180);
  const { session } = b2x.useAppContext();

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page className="product-page" noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {/* <hr />
      <div>{b2x.prettyStringify(productInShippingCountryLocale)}</div>
      <hr /> */}
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <React.Fragment>
              <b2x.structuredData.Product product={product} selectedSku={productContext.selectedSku} />
              <section className="product-first-section py-lg-5">
                <b2x.Container>
                  {b2x.untilBreakpoint('md', currentBreakpoint) && (
                    <b2x.Col size={12}>
                      <div className="mb-2 px-3 p-lg-0">
                        <Breadcrumb hiddenLastItem />
                      </div>
                    </b2x.Col>
                  )}
                  <b2x.Row>
                    <b2x.Col size={{ lg: 6, xs: 12 }}>
                      <div className="position-relative">
                        <div className="btn-wishlist-container position-absolute end-0 p-3" style={{ zIndex: 100 }}>
                          <b2x.WishlistButtonHelper product={product} sku={productContext.selectedSku}>
                            {({ handleWishlistButtonClick, inWishlist }) => (
                              <Button
                                className="text-light-blue"
                                iconEnd={{
                                  name: inWishlist ? 'wishlist-full' : 'wishlist',
                                  size: 30,
                                }}
                                onClick={handleWishlistButtonClick}
                                variant="blank"
                              />
                            )}
                          </b2x.WishlistButtonHelper>
                        </div>
                        <ProductGallery
                          selectedProductVariant={productContext.selectedProductVariant}
                          selectedSku={productContext.selectedSku}
                        />
                      </div>
                    </b2x.Col>
                    <b2x.Col size={{ lg: 6, xs: 12 }}>
                      <div className="px-3 p-lg-0">
                        {!b2x.untilBreakpoint('md', currentBreakpoint) && <Breadcrumb hiddenLastItem />}
                        {productContext.selectedProductVariant.name && (
                          <h1 className="product-title h2 fw-bold">
                            {b2x.formatHtml(productContext.selectedProductVariant.name)}
                          </h1>
                        )}
                        {productContext.selectedSku?.name && (
                          <p className="small mb-2 mb-md-3">{b2x.formatHtml(productContext.selectedSku.name)}</p>
                        )}
                        {productContext.selectedProductVariant.descriptionShort && (
                          <div className="product-short-description small mb-2 mb-md-3">
                            {b2x.formatHtml(productContext.selectedProductVariant.descriptionShort)}
                          </div>
                        )}
                        <b2x.AddToCartFormHelper className="mb-3 mb-md-5" product={product} scope="product">
                          {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                            <React.Fragment>
                              {fieldsHelper.skus.formFields.length > 1 && (
                                <b2x.FormGroup {...fieldsHelper.skus.formGroup} noMarginBottom>
                                  <b2x.Select {...fieldsHelper.skus.select}>
                                    {fieldsHelper.skus.formFields.map(({ option, sku }) => (
                                      <b2x.Option key={sku.id} {...option} />
                                    ))}
                                  </b2x.Select>
                                </b2x.FormGroup>
                              )}
                              <div ref={variantsDivContainer}>
                                <b2x.FormGroup
                                  {...fieldsHelper.productVariants.formGroup}
                                  className="mb-2 mb-md-4"
                                  labelClassName="small"
                                  noMarginBottom
                                >
                                  {fieldsHelper.productVariants.formFields.map((formField, index) => (
                                    <b2x.Radio
                                      className="mb-2"
                                      key={formField.productVariant.id}
                                      {...formField.radio}
                                      inline
                                    >
                                      {formField.productVariant.skus?.map(
                                        (sku, indexSku) =>
                                          indexSku === 0 && (
                                            <CustomSkuRadio
                                              key={sku.id}
                                              label={`${sku.measurement?.value} ${sku.measurement?.measurementUnit}`}
                                              sku={sku}
                                            />
                                          )
                                      )}
                                    </b2x.Radio>
                                  ))}
                                </b2x.FormGroup>
                              </div>
                              <ProductPrice priceHelper={priceHelper} />
                              <div className="hstack gap-3 mb-3 mb-md-4">
                                {selectedSku?.state === 'AVAILABLE' && (
                                  <QuantityInputGroup
                                    decreaseButton={fieldsHelper.buttons.quantity.decrease}
                                    increaseButton={fieldsHelper.buttons.quantity.increase}
                                    quantity={formik.values.quantity}
                                  />
                                )}
                                <div className="add-to-cart-button-container">
                                  <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                    fieldsHelper={fieldsHelper}
                                    selectedSku={selectedSku}
                                    submitButton={
                                      b2x.untilBreakpoint('xs', currentBreakpoint)
                                        ? {
                                            label:
                                              selectedSku?.state === 'AVAILABLE'
                                                ? t('misc.addToCart')
                                                : t('misc.productNotAvailable'),
                                            variant: selectedSku?.state === 'AVAILABLE' ? 'primary' : 'outline-primary',
                                          }
                                        : {
                                            label:
                                              selectedSku?.state === 'AVAILABLE'
                                                ? t('form.addToCartForm.buttons.submit')
                                                : t('misc.productNotAvailable'),
                                            variant: selectedSku?.state === 'AVAILABLE' ? 'primary' : 'outline-primary',
                                          }
                                    }
                                  />
                                </div>
                              </div>
                              <b2x.Portal>
                                <b2x.ProductStickyFooter
                                  fieldsHelper={fieldsHelper}
                                  formik={formik}
                                  isVisible={!isTopBarRowVisible && !isFooterCopyrightVisible}
                                  priceHelper={priceHelper}
                                  product={product}
                                  scrollToElement={scrollToVariantsDivContainer}
                                  selectedProductImage={selectedProductVariant.image}
                                  selectedSku={selectedSku}
                                />
                              </b2x.Portal>
                            </React.Fragment>
                          )}
                        </b2x.AddToCartFormHelper>
                        <ProductPromoCode attributes={product.attributes} />
                        {session?.cart?.shippingProfile?.freeThreshold && (
                          <div className="alert alert-info small fw-bold text-light-blue border-0 hstack gap-2 mb-0">
                            {/* <Icon name="delete" size={24} /> */}
                            {b2x.formatHtml(
                              t('product.freeShippingFrom', {
                                freeShippingFromAmount: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
                              })
                            )}
                          </div>
                        )}
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </b2x.Container>
              </section>
              <SecondSection product={productContext.selectedProductVariant} />
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <section className="product-third-section py-md-5">
                        <b2x.Container>
                          <h3
                            className={classnames(
                              { h1: !b2x.untilBreakpoint('sm', currentBreakpoint) },
                              'fw-bold text-uppercase text-center mb-md-5'
                            )}
                          >
                            {t('product.relatedProduct.title')}
                          </h3>
                          <b2x.Listing
                            name="Product page - Related products"
                            products={relatedProducts}
                            sendEvent={false}
                          >
                            <ProductsSlider products={relatedProducts} />
                          </b2x.Listing>
                        </b2x.Container>
                      </section>
                    )
                  )
                }
              </b2x.RelatedProducts>
            </React.Fragment>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductPriceProps {
  priceHelper: b2x.UsePriceResult;
}

const ProductPrice = ({ priceHelper }: ProductPriceProps) => {
  return (
    <div className="product-price-container mb-3">
      <div className="mb-2">
        <b2x.PriceBlock priceHelper={priceHelper} />
      </div>
      <div className="extra-small">
        {priceHelper.perUnit?.price && (
          <div>
            {`(${t('misc.pricePerUnit', {
              measurementUnit: priceHelper.perUnit.measurement,
              price: b2x.formatCurrency(priceHelper.perUnit.price),
            })})`}
          </div>
        )}
        {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && <b2x.BestPrice priceHelper={priceHelper} />}
      </div>
    </div>
  );
};

interface ProductGalleryProps {
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductGallery = ({ selectedProductVariant, selectedSku }: ProductGalleryProps) => {
  const images = React.useMemo(
    () => [
      selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
      ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
    ],
    [selectedProductVariant.alternativeImages, selectedProductVariant.image, selectedSku?.image]
  );

  const [thumbsSwiper, setThumbsSwiper] = React.useState<b2x.SwiperClass>();

  return (
    <div className="product-gallery d-flex mb-3">
      <div className="gallery-thumbnails d-none d-lg-block">
        <b2x.SwiperContext>
          <b2x.Swiper
            direction="vertical"
            onSwiper={setThumbsSwiper}
            slides={images.map(
              (image) =>
                image && (
                  <div key={image.id}>
                    <b2x.Image {...image} fluid />
                  </div>
                )
            )}
            slidesPerView={'auto'}
            spaceBetween={16}
            watchSlidesProgress
          />
        </b2x.SwiperContext>
      </div>
      <div className="gallery-slides ps-0 ps-lg-3">
        <b2x.Lightbox>
          <b2x.SwiperContext>
            <b2x.Swiper
              pagination
              slides={images.map(
                (image) =>
                  image && (
                    <div key={image.id}>
                      <b2x.LightboxItem src={image.src}>
                        <b2x.Image {...image} fluid />
                      </b2x.LightboxItem>
                    </div>
                  )
              )}
              thumbs={{
                slideThumbActiveClass: 'border',
                swiper: thumbsSwiper,
              }}
            />
          </b2x.SwiperContext>
        </b2x.Lightbox>
      </div>
    </div>
  );
};

interface SecondSectionProps {
  product: b2x.ProductApiDto;
}

const SecondSection = ({ product }: SecondSectionProps) => {
  const minutiCottura = product.attributes?.find((attribute) => attribute.typeCode === 'DCC_Minuti_cottura')?.value;
  const minutiDente = product.attributes?.find((attribute) => attribute.typeCode === 'DCC_minuti_al_dente')?.value;
  const essiccazione = product.attributes?.find((attribute) => attribute.typeCode === 'DCC_essiccazione')?.value;
  const calorie = product.attributes?.find((attribute) => attribute.typeCode === 'DCC_calorie_100g')?.value;
  const fotoSchedaProdotto = product.attributes?.find(
    (attribute) => attribute.typeCode === 'DCC_foto_scheda_prodotto'
  )?.value;
  const videoSchedaProdotto = product.attributes?.find(
    (attribute) => attribute.typeCode === 'DCC_video_scheda_prodotto'
  )?.value;

  const isPastaSecca = minutiCottura || minutiDente || essiccazione || calorie;

  const shippingCountryInfo = b2x.useShippingCountryInfo();

  return (
    <section className="product-second-section py-3 mb-3 py-md-5 mb-md-5 position-relative">
      {isPastaSecca && fotoSchedaProdotto && (
        <div className="position-absolute w-100 h-100 d-none d-xl-block">
          <b2x.Div alignItems={'center'} className="h-100" display={'flex'} justifyContent={'center'}>
            <b2x.Image fluid src={fotoSchedaProdotto} />
          </b2x.Div>
        </div>
      )}
      <b2x.Container className="position-relative" style={{ zIndex: 100 }}>
        <b2x.Row>
          <b2x.Col size={{ lg: 6, xs: 12 }}>
            <Accordion alwaysOpened id="product-accordion">
              {product.description && (
                <b2x.Translations
                  targetLocale={shippingCountryInfo?.locale}
                  texts={[t('product.description'), product.description]}
                >
                  {(translations) => (
                    <b2x.AccordionItem
                      id="product-description"
                      show
                      title={
                        <div className="hstack w-100 justify-content-between pe-3">
                          <span>{translations.result?.at(0) ?? ''}</span>
                          {!translations.disabled && (
                            // <b2x.Badge backgroundColor="primary" pill textColor="secondary">
                            //   {shippingCountryInfo?.i18n.translatableInThisLocale}
                            // </b2x.Badge>
                            <span className="extra-small">{shippingCountryInfo?.i18n.translatableInThisLocale}</span>
                          )}
                        </div>
                      }
                    >
                      <div className="mb-3">{b2x.formatHtml(translations.result?.at(1))}</div>
                      {product.code && (
                        <div className="text-primary text-gray-400 small">
                          {t('product.code')}: {product.code.replace('B2X_', '')}
                        </div>
                      )}
                      {!translations.disabled && (
                        <div className="mt-3">
                          <Button
                            label={`${
                              translations.fetching
                                ? shippingCountryInfo?.i18n.translating
                                : translations.activeText === 'original'
                                ? shippingCountryInfo?.i18n.translateInThisLocale
                                : shippingCountryInfo?.i18n.restoreOriginalLocale
                            }`}
                            onClick={translations.toggle}
                            size="small"
                          />
                        </div>
                      )}
                    </b2x.AccordionItem>
                  )}
                </b2x.Translations>
              )}

              {product.attributes?.map(
                (attribute) =>
                  (attribute.typeCode === 'DCC_ingredienti' ||
                    attribute.typeCode === 'DCC_luogo_origine' ||
                    attribute.typeCode === 'DCC_informazioni_cottura' ||
                    attribute.typeCode === 'DCC_scadenza_minima' ||
                    attribute.typeCode === 'DCC_modalita_conservazione' ||
                    attribute.typeCode === 'DCC_valori_nutrizionali') &&
                  attribute.value && (
                    <b2x.Translations
                      key={attribute.typeCode}
                      targetLocale={shippingCountryInfo?.locale}
                      texts={[attribute.title, attribute.value]}
                    >
                      {(translations) => (
                        <b2x.AccordionItem
                          id={attribute.typeCode}
                          title={
                            <div className="hstack w-100 justify-content-between pe-3">
                              <span>{translations.result?.at(0) ?? ''}</span>
                              {!translations.disabled && (
                                // <b2x.Badge backgroundColor="primary" pill textColor="secondary">
                                //   {shippingCountryInfo?.i18n.translatableInThisLocale}
                                // </b2x.Badge>
                                <span className="extra-small">
                                  {shippingCountryInfo?.i18n.translatableInThisLocale}
                                </span>
                              )}
                            </div>
                          }
                        >
                          {b2x.formatHtml(translations.result?.at(1))}
                          {!translations.disabled && (
                            <div className="mt-3">
                              <Button
                                disabled={translations.disabled}
                                label={`${
                                  translations.fetching
                                    ? shippingCountryInfo?.i18n.translating
                                    : translations.activeText === 'original'
                                    ? shippingCountryInfo?.i18n.translateInThisLocale
                                    : shippingCountryInfo?.i18n.restoreOriginalLocale
                                }`}
                                onClick={translations.toggle}
                                size="small"
                              />
                            </div>
                          )}
                        </b2x.AccordionItem>
                      )}
                    </b2x.Translations>
                  )
              )}
            </Accordion>
          </b2x.Col>
          {isPastaSecca ? (
            <b2x.Col size={{ lg: 6, xs: 12 }}>
              <div className="text-light-blue">
                {essiccazione && (
                  <div className="hstack gap-3">
                    <div className="pasta-info-icon">
                      <b2x.Image fluid src={imageEssiccazione} />
                    </div>
                    <div>
                      <h3 className="display-1 fw-bold">{essiccazione}</h3>
                      <p className="h4 primary-font fw-bold">{t('product.dryingTime')}</p>
                    </div>
                  </div>
                )}
                {minutiCottura && (
                  <div className="hstack gap-3">
                    <div className="pasta-info-icon">
                      <b2x.LottieElement src={animazioneSveglia as unknown as string} />
                    </div>
                    <div>
                      <h3 className="display-1 fw-bold">{minutiCottura}</h3>
                      <p className="h4 primary-font fw-bold">{t('product.cookingMinutes')}</p>
                    </div>
                  </div>
                )}
                {minutiDente && (
                  <div className="hstack gap-3">
                    <div className="pasta-info-icon">
                      <b2x.Image fluid src={imageAlDente} />
                    </div>
                    <div>
                      <h3 className="display-1 fw-bold">{minutiDente}</h3>
                      <p className="h4 primary-font fw-bold">{t('product.minutesOfCookingAlDente')}</p>
                    </div>
                  </div>
                )}
                {calorie && (
                  <div className="hstack gap-3">
                    <div className="pasta-info-icon">
                      <b2x.Image fluid src={{ xs: imageKcal }} />
                    </div>
                    <div>
                      <h3 className="display-1 fw-bold">{calorie}</h3>
                      <p className="h4 primary-font fw-bold">{t('product.caloriesPer100g')}</p>
                    </div>
                  </div>
                )}
              </div>
            </b2x.Col>
          ) : (
            <b2x.Col className="d-none d-lg-block" size={6}>
              <div className="rounded-3 overflow-hidden">
                <b2x.VideoFromContent variants={{ xs: { source: { bcom: { file: videoSchedaProdotto } } } }} />
                {videoSchedaProdotto ? (
                  <b2x.Video
                    options={{ autoplay: true, controls: false, loop: true, muted: true }}
                    variants={{ xs: { height: 650, source: { bcom: { file: videoSchedaProdotto } }, width: 700 } }}
                  />
                ) : (
                  fotoSchedaProdotto && <b2x.Image fluid src={fotoSchedaProdotto} />
                )}
              </div>
            </b2x.Col>
          )}
        </b2x.Row>
      </b2x.Container>
    </section>
  );
};

interface ProductPromoCodePros {
  attributes?: Array<b2x.AttributeApiDto>;
}

const ProductPromoCode = ({ attributes }: ProductPromoCodePros) => {
  // Recupero il valore dall'attributo impostato.
  const productPromoCodeContent = attributes?.find(
    (attribute) => attribute.typeCode === 'DCC_PROMO_CONTENT_CODE'
  )?.value;

  const miscContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  // Se presente un valore, recupero il contenuto del banner.
  const content = productPromoCodeContent && b2x.useContent<ProductPromoCodeContentType>(productPromoCodeContent);
  const { showModal } = b2x.useModals();

  const codeRef = React.useRef<HTMLSpanElement>(null);
  const handleCopyCodeButtonClick = React.useCallback(() => {
    const couponCode = codeRef.current?.innerText ?? '';
    navigator.clipboard.writeText(couponCode).then((response) => {
      showModal({
        autoClose: 2,
        centered: true,
        children: miscContent?.body.productPage?.couponCodeBanner?.contentModal,
        className: 'modal-copy-coupon',
        closable: false,
        title: miscContent?.body.productPage?.couponCodeBanner?.titleModal || '',
      });
    });
  }, [
    miscContent?.body.productPage?.couponCodeBanner?.contentModal,
    miscContent?.body.productPage?.couponCodeBanner?.titleModal,
    showModal,
  ]);

  return (
    <>
      {typeof content === 'object' && content.body.backgroundColor && (
        <div
          className="product-promo-code mb-4 p-2"
          style={{
            background: content.body.backgroundColor,
          }}
        >
          <b2x.Row className="align-items-center" gap={3}>
            <b2x.Col size={'auto'}>
              {content.body.icon ? (
                <Icon className="text-red me-3 me-xl-0" name={content.body.icon as IconName} size={25} />
              ) : (
                <b2x.LottieElement className="ringbell" src={animazioneCampanella as unknown as string} />
              )}
            </b2x.Col>
            <b2x.Col size={''}>
              <div className="small ">{b2x.formatHtml(content.body.description)}</div>
              <div className="extra-small mb-1 mb-xl-0">
                <span className="text-uppercase extra-small"> {t('misc.code')}: </span>
                <span className="fw-bold extra-small " ref={codeRef}>
                  {b2x.formatHtml(content.body.couponCode)}
                </span>
              </div>
            </b2x.Col>

            <b2x.Col className="button-col" size={{ sm: 'auto', xl: 'auto', xs: 12 }}>
              <div className="pb-3 pb-sm-0">
                <b2x.Button
                  label={content.body.cta?.label}
                  onClick={handleCopyCodeButtonClick}
                  variant={content.body.cta?.variant}
                />
              </div>
            </b2x.Col>
          </b2x.Row>
        </div>
      )}
    </>
  );
};
